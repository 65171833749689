// declare const window: any

import { Box, Container, styled } from '@material-ui/core'
import { PageProps } from 'gatsby'
import React, { useEffect, useState } from 'react'
import { useMediaQuery } from 'react-responsive'

import Spinner from '../../../components/loading/spinner'
import Navbar from '../../../components/navbar'
import MobileNavbar from '../../../components/navbar/mobile'
import PageHeader from '../../../components/page-header'
import PaymentDetailsTill from '../../../components/payments/payment-details-till'
import PaymentDetailsStripe from '../../../components/payments/payment-details-stripe'
import { useCart } from '../../../hooks/useCart'
import { CartType } from '../../../services/api/endpoints/carts'

const StyledContainer = styled(Box)`
  ${({ theme }) => {
    return {
      [theme.breakpoints.down(600)]: {
        marginTop: '2rem',
      },
    }
  }}
  margin-top: 3rem;
`

const RegisterRefereePaymentPage = (props: PageProps) => {
  const cartType: CartType = 'referee'
  const [paymentProvider, setPaymentProvider] = useState<
    'till' | 'stripe' | null
  >(null)

  const { cart, isCartLoading } = useCart({
    type: cartType,
  })

  const isMobile = useMediaQuery({ query: `(max-width: 945px)` })

  useEffect(() => {
    if (!isCartLoading && cart?.data) {
      if (cart?.data?.association?.stripeChargesEnabled) {
        setPaymentProvider('stripe')
      } else {
        setPaymentProvider('till')
      }
    }
  }, [cart?.data, isCartLoading])

  let content = (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: 'calc(100vh - 145px);',
      }}
    >
      <Spinner size={40} color="#008174" />
    </Box>
  )

  if (paymentProvider === 'till') {
    content = <PaymentDetailsTill type={cartType} />
  }

  if (paymentProvider === 'stripe') {
    content = <PaymentDetailsStripe type={cartType} />
  }

  return (
    <>
      {isMobile ? (
        <MobileNavbar bg="#008174" />
      ) : (
        <Navbar path={props.location.pathname} bg="#008174" />
      )}
      <Container>
        <StyledContainer>
          <PageHeader title="PAYMENT DETAILS" />
          {content}
        </StyledContainer>
      </Container>
    </>
  )
}

export default RegisterRefereePaymentPage
